import React, { useEffect, useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import * as RiIcons from 'react-icons/ri';
import * as FcIcons from 'react-icons/fc';

const HomePage = (props) => {
    const price = props.price

    const navigate = useNavigate()
    const [delay, setDelay] = useState(true)
    const [product,setProduct] = useState([])

    const [selectedPriceRange, setSelectedPriceRange] = useState('Price Filter');
    const [selectedCategory, setSelectedCategory] = useState('Category');

    // {/* Product Zoom Big Panel */}
    // const [zoom, setZoom] = useState('')
    // const [zoom1, setZoom1] = useState('')
    // const [zoom2, setZoom2] = useState('')
    // const [zoom3, setZoom3] = useState('')

    // const [data1, setData1] = useState('')
    // const [data2, setData2] = useState('')
    // const [data3, setData3] = useState('')
    // const [data4, setData4] = useState('')
    // const des = data4.split('- ')

    // Get Data
    const getProduct = async () => {
        let result = await fetch('https://api.zakhira.store/products')
        result = await result.json()
        if(result){
            let  inStock = result.filter((i)=>(i.stock===true))
            let  outStock = result.filter((i)=>(i.stock===false))
            setProduct([...outStock, ...inStock])
        }
    }
    useEffect(()=>{
        setTimeout(() => setDelay(false), 9999)
        getProduct()
        // eslint-disable-next-line 
    },[])

    let filter01, filter02, filter03, filter04, filter05, filter06, filter07, filter08, filter09, filter10, filter11, filter12, filter13, filter14, filter15
    let filter1, filter100, filter200, filter300, filter400, filter500, filter600, filter700, filter800, filter900
    
    filter01 = product.filter((i)=>(i.category==="Wallets"))
    filter02 = product.filter((i)=>(i.category==="Belts"))
    filter03 = product.filter((i)=>(i.category==="Watches"))
    filter04 = product.filter((i)=>(i.category==="Bags"))
    filter05 = product.filter((i)=>(i.category==="Accessories"))
    filter06 = product.filter((i)=>(i.category==="Shoes"))
    filter07 = product.filter((i)=>(i.category==="Sunglasses"))
    filter08 = product.filter((i)=>(i.category==="Tshirts"))
    filter09 = product.filter((i)=>(i.category==="Shirts"))
    filter10 = product.filter((i)=>(i.category==="Jeans"))
    filter11 = product.filter((i)=>(i.category==="Trousers"))
    filter12 = product.filter((i)=>(i.category==="Jackets"))
    filter13 = product.filter((i)=>(i.category==="Under Garments"))
    filter14 = product.filter((i)=>(i.category==="Socks"))
    filter15 = product.filter((i)=>(i.category==="Accessories"))

    if(price==='offer'){
        filter1 = product.filter((i)=>(i.offer<=999))
        filter100 = product.filter((i)=>(i.offer>=1000&&i.offer<=1999))
        filter200 = product.filter((i)=>(i.offer>=2000&&i.offer<=2999))
        filter300 = product.filter((i)=>(i.offer>=3000&&i.offer<=3999))
        filter400 = product.filter((i)=>(i.offer>=4000&&i.offer<=4999))
        filter500 = product.filter((i)=>(i.offer>=5000&&i.offer<=5999))
        filter600 = product.filter((i)=>(i.offer>=6000&&i.offer<=6999))
        filter700 = product.filter((i)=>(i.offer>=7000&&i.offer<=7999))
        filter800 = product.filter((i)=>(i.offer>=8000&&i.offer<=8999))
        filter900 = product.filter((i)=>(i.offer>=9000&&i.offer<=9999))
    }
    else{
        filter1 = product.filter((i)=>(i.wholesale<=999))
        filter100 = product.filter((i)=>(i.wholesale>=1000&&i.wholesale<=1999))
        filter200 = product.filter((i)=>(i.wholesale>=2000&&i.wholesale<=2999))
        filter300 = product.filter((i)=>(i.wholesale>=3000&&i.wholesale<=3999))
        filter400 = product.filter((i)=>(i.wholesale>=4000&&i.wholesale<=4999))
        filter500 = product.filter((i)=>(i.wholesale>=5000&&i.wholesale<=5999))
        filter600 = product.filter((i)=>(i.wholesale>=6000&&i.wholesale<=6999))
        filter700 = product.filter((i)=>(i.wholesale>=7000&&i.wholesale<=7999))
        filter800 = product.filter((i)=>(i.wholesale>=8000&&i.wholesale<=8999))
        filter900 = product.filter((i)=>(i.wholesale>=9000&&i.wholesale<=9999))
    }

    const filteredProducts = product.filter((i) => {
    
    const retailCondition =
      selectedPriceRange === 'Price Filter' ||
      (selectedPriceRange === '₹1 - ₹999' && i.offer <= 999) ||
      (selectedPriceRange === '₹1000 - ₹1999' && i.offer >= 1000 && i.offer <= 1999) ||
      (selectedPriceRange === '₹2000 - ₹2999' && i.offer >= 2000 && i.offer <= 2999) ||
      (selectedPriceRange === '₹3000 - ₹3999' && i.offer >= 3000 && i.offer <= 3999) ||
      (selectedPriceRange === '₹4000 - ₹4999' && i.offer >= 4000 && i.offer <= 4999) ||
      (selectedPriceRange === '₹5000 - ₹5999' && i.offer >= 5000 && i.offer <= 5999) ||
      (selectedPriceRange === '₹6000 - ₹6999' && i.offer >= 6000 && i.offer <= 6999) ||
      (selectedPriceRange === '₹7000 - ₹7999' && i.offer >= 7000 && i.offer <= 7999) ||
      (selectedPriceRange === '₹8000 - ₹8999' && i.offer >= 8000 && i.offer <= 8999) ||
      (selectedPriceRange === '₹9000 - ₹9999' && i.offer >= 9000 && i.offer <= 9999);
    
    const wholesaleCondition =
      selectedPriceRange === 'Price Filter' ||
      (selectedPriceRange === '₹1 - ₹999' && i.offer <= 999) ||
      (selectedPriceRange === '₹1000 - ₹1999' && i.offer >= 1000 && i.offer <= 1999) ||
      (selectedPriceRange === '₹2000 - ₹2999' && i.offer >= 2000 && i.offer <= 2999) ||
      (selectedPriceRange === '₹3000 - ₹3999' && i.offer >= 3000 && i.offer <= 3999) ||
      (selectedPriceRange === '₹4000 - ₹4999' && i.offer >= 4000 && i.offer <= 4999) ||
      (selectedPriceRange === '₹5000 - ₹5999' && i.offer >= 5000 && i.offer <= 5999) ||
      (selectedPriceRange === '₹6000 - ₹6999' && i.offer >= 6000 && i.offer <= 6999) ||
      (selectedPriceRange === '₹7000 - ₹7999' && i.offer >= 7000 && i.offer <= 7999) ||
      (selectedPriceRange === '₹8000 - ₹8999' && i.offer >= 8000 && i.offer <= 8999) ||
      (selectedPriceRange === '₹9000 - ₹9999' && i.offer >= 9000 && i.offer <= 9999);

    const priceCondition = price === 'offer' ? retailCondition : wholesaleCondition;
    const categoryCondition = selectedCategory === 'Category' || selectedCategory === i.category;

    return priceCondition && categoryCondition;
  });

    const logout = () => {
        localStorage.clear()
        navigate('/')
    }
    
return(
    <div className='container-fluid no-nav bg-dark'>

        <link rel="stylesheet" href="main.css"/>
        
        {/* { product.length>0 &&
        <h2 className='float-msg message'><b>Take&nbsp;Screeshot&nbsp;&&nbsp;Share&nbsp;On&nbsp;WhatsApp +91&nbsp;799&nbsp;162&nbsp;0001</b></h2> } */}

        { price==='wholesale' &&
        <NavLink className="nav-link active text-white logout float-log mt-5" to="/" onClick={logout} ><RiIcons.RiLogoutCircleLine/></NavLink>}

        {/* <!-- Products Tab area Start --> */}
        <div className="container product-tab pt--80 pb--60 pt-md--80 pb-md--45">
            
            <div className='row align-items-center'>
                <div className='col-4 left p-md-4'>
                    { product.length>0 &&
                    <>
                    <select className='btn btn-dark filter-btn p-0' onChange={(e)=>setSelectedPriceRange(e.target.value)}>
                        <option>Price Filter</option>
                        {filter1.length!==0?<option>₹1 - ₹999</option>:<option disabled>₹1 - ₹999</option>}
                        {filter100.length!==0?<option>₹1000 - ₹1999</option>:<option disabled>₹1000 - ₹1999</option>}
                        {filter200.length!==0?<option>₹2000 - ₹2999</option>:<option disabled>₹2000 - ₹2999</option>}
                        {filter300.length!==0?<option>₹3000 - ₹3999</option>:<option disabled>₹3000 - ₹3999</option>}
                        {filter400.length!==0?<option>₹4000 - ₹4999</option>:<option disabled>₹4000 - ₹4999</option>}
                        {filter500.length!==0?<option>₹5000 - ₹5999</option>:<option disabled>₹5000 - ₹5999</option>}
                        {filter600.length!==0?<option>₹6000 - ₹6999</option>:<option disabled>₹6000 - ₹6999</option>}
                        {filter700.length!==0?<option>₹7000 - ₹7999</option>:<option disabled>₹7000 - ₹7999</option>}
                        {filter800.length!==0?<option>₹8000 - ₹8999</option>:<option disabled>₹8000 - ₹8999</option>}
                        {filter900.length!==0?<option>₹9000 - ₹9999</option>:<option disabled>₹9000 - ₹9999</option>}
                    </select>
                    <select className='btn btn-dark filter-btn p-0' onChange={(e)=>setSelectedCategory(e.target.value)}>
                        <option>Category</option>
                        {filter01.length!==0?<option>Wallets</option>       :<option disabled>Wallets</option>}
                        {filter02.length!==0?<option>Belts</option>         :<option disabled>Belts</option>}
                        {filter03.length!==0?<option>Watches</option>       :<option disabled>Watches</option>}
                        {filter04.length!==0?<option>Bags</option>          :<option disabled>Bags</option>}
                        {filter05.length!==0?<option>Accessories </option>  :<option disabled>Accessories</option>}
                        {filter06.length!==0?<option>Shoes</option>         :<option disabled>Shoes</option>}
                        {filter07.length!==0?<option>Sunglasses</option>    :<option disabled>Sunglasses</option>}
                        {filter08.length!==0?<option>Tshirts</option>       :<option disabled>Tshirts</option>}
                        {filter09.length!==0?<option>Shirts</option>        :<option disabled>Shirts</option>}
                        {filter10.length!==0?<option>Jeans</option>         :<option disabled>Jeans</option>}
                        {filter11.length!==0?<option>Trousers</option>      :<option disabled>Trousers</option>}
                        {filter12.length!==0?<option>Jackets</option>       :<option disabled>Jackets</option>}
                        {filter13.length!==0?<option>Under Garments</option>:<option disabled>Under Garments</option>}
                        {filter14.length!==0?<option>Socks</option>         :<option disabled>Socks</option>}
                        {filter15.length!==0?<option>Accessories</option>   :<option disabled>Accessories</option>}
                    </select>
                    </>
                    }
                </div>
                
                <div className='col-4'>
                    <img loading='lazy' src="images/logo.png" alt='' height={100} width={100} />
                </div>
                <div className='col-4 right'>
                    {product.length>0 &&
                    <a target='blank' href='https://api.whatsapp.com/send/?phone=7991620001&text=Hi&type=phone_number&app_absent=0'>
                        <img loading='lazy' src='images/button.png' className='rounded' alt='' height={50} />
                    </a>}
                </div>
            </div>

            
            <div className="product-carousel js-product-carousel row">

            {
                filteredProducts.length>0?
                filteredProducts.slice(0).reverse().map((i,index)=>(
                    
                <div className="product-carousel-group col-6 col-md-4 col-lg-3 col-xl-3 mt-4" key={index} >
                <div className="mirora-product">
                        <div className="product-img">
                            <center>
                                {/* out of stock image */}
                                {/* {i.stock?null:<img loading='lazy' src='images/out.png' className='stock' alt='' />} */}
                                <img loading='lazy' src={`https://api.zakhira.store/${i.images && i.images[0]}`} alt='' className="primary-image list-img" />
                            </center>
                            {product.length>0 ?
                            <img loading='lazy' src={`https://api.zakhira.store/${i.images && i.images[0]}`} alt='' className="secondary-image list-img" />:
                            <img loading='lazy' src={`https://api.zakhira.store/${i.images && i.images[1]}`} alt='' className="secondary-image list-img" />}
                            <div className="product-img-overlay">
                                <span className="per product-label discount p-1"><p>{(Math.round(i.offer/i.retail*100))-100}%</p></span>
                                <NavLink className="btn btn-transparent btn-fullwidth btn-medium btn-style-1" to={`/${price==='offer'?'product':'userProduct'}/`+i._id}>Quick View</NavLink>
                                
                                {/* Product Zoom Big Panel */}
                                {/* {
                                    price==='offer'?
                                    <p data-bs-toggle="modal" data-bs-target="#productModal" className="btn btn-transparent btn-fullwidth btn-medium btn-style-1" 
                                    onClick={ ()=>{ setZoom(i.images && i.images[0]); setZoom1(i.images && i.images[0]); setZoom2(i.images && i.images[1]); setZoom3(i.images && i.images[2]); 
                                    setData1(i.title); setData2(i.offer); setData3(i.retail); setData4(i.description); } }>Quick View</p>
                                    :
                                    <p data-bs-toggle="modal" data-bs-target="#productModal" className="btn btn-transparent btn-fullwidth btn-medium btn-style-1" 
                                    onClick={ ()=>{ setZoom(i.images && i.images[0]); setZoom1(i.images && i.images[0]); setZoom2(i.images && i.images[1]); setZoom3(i.images && i.images[2]); 
                                    setData1(i.title); setData2(i.wholesale); setData3(i.offer); setData4(i.description); } }>Quick View</p>
                                } */}

                            </div>
                        </div>
                        <div className="text-center">
                            <h4 className='mt-4 mb-3'>{i.stock?<span>In Stock</span>:<span className='text-danger'>Check Availability</span>}</h4>
                            <h3 className='title'><NavLink className="text-light" to={`/${price==='offer'?'product':'userProduct'}/`+i._id}>{i.title}</NavLink></h3>
                            <center><hr className='text-secondary' width='70%' /></center>
                            <div className="product-price-wrapper">
                                
                            {
                                price==='offer'?
                                <>
                                    <span>Offer Price: ₹{i.offer}.00</span>
                                    <div className="pt-2">Retail Price: <del>₹{i.retail}.00</del></div>
                                    <h4 className="golden pt-2">{i.offer<1?'Shipping Extra':'Shipping Extra'}</h4>
                                </>:
                                <>
                                    <span>Reseller Price: ₹{i.wholesale}.00</span>
                                    <div className="pt-2">Offer Price: <del>₹{i.offer}.00</del></div>
                                    <div className="golden pt-2">{i.wholesale<1?'Shipping Extra':'Shipping Extra'}</div>
                                </>
                            }
                            
                            </div>
                        </div>
                        <div className="mirora_product_action text-center position-absolute">
                            {i.description.split('- ')[0]?
                            <p className='description'><FcIcons.FcApproval className='homecheck'/>
                                {i.description.split('- ')[0]}...<br/>
                            </p>:
                            <p className='description' style={{paddingLeft: '15%'}}>
                                <li className='left'>{i.description.split('- ')[1]}</li>
                                <li className='left'>{i.description.split('- ')[2]}...</li>
                            </p>}
                            <a target='blank' href={price==='offer'?
                                // i.offer<1?`https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${i.images && i.images[0]}   ${i.description.split('- ')[1].replace('&','＆')}   |   Offer Price : ₹${i.offer}.00   |   Shipping Charges : ₹199.00&type=phone_number&app_absent=0`
                                // :`https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${i.images && i.images[0]}   ${i.description.split('- ')[1].replace('&','＆')}   |   Offer Price : ₹${i.offer}.00   |   Shipping Charges : Free !&type=phone_number&app_absent=0`
                                // :
                                // i.wholesale<1?`https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${i.images && i.images[0]}   ${i.description.split('- ')[1].replace('&','＆')}   |   Reseller Price : ₹${i.wholesale}.00   |   Shipping Charges : ₹199.00&type=phone_number&app_absent=0`
                                // :`https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${i.images && i.images[0]}   ${i.description.split('- ')[1].replace('&','＆')}   |   Reseller Price : ₹${i.wholesale}.00   |   Shipping Charges : Free !&type=phone_number&app_absent=0`
                                `https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${i.images && i.images[0]}   |   Category : ${i.category}   |   Offer Price : ₹${i.offer}.00   |   Shipping Extra&type=phone_number&app_absent=0`
                                :
                                `https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${i.images && i.images[0]}   |   Category : ${i.category}   |   Reseller Price : ₹${i.wholesale}.00   |   Shipping Extra&type=phone_number&app_absent=0`
                            }>
                                <img loading='lazy' src='images/send.png' className='send rounded mb-5' alt='' height={35} />
                            </a>
                            
                            <center><hr className='text-secondary' width='70%' /></center>
                        </div>
                </div>
                </div>
                )) :
                delay?
                <h2 className='mt-5'>Loading <div className="spinner-border" role="status"></div></h2>:
                <h2 className='text-danger mt-5'>No Product Found</h2>
            }

            </div>

        </div>
        {/* <!-- Products Tab area End --> */}
        


        {/* Product Zoom Big Panel */}

        {/* <!-- Modal Start --> */}
        {/* <div className="modal fade product-modal" id="productModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-body blur">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><b>Close</b></span>
                </button>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 mb-sm--20">
                            <div className="tab-content product-thumb-large">
                                <div className="tab-pane active show fade">
                                    <img loading='lazy' src={`https://api.zakhira.store/${zoom}`} alt='' />
                                </div>
                            </div>

                            <div className="product-thumbnail min">
                                <div className="thumb-menu row">
                                    <div className="thumb-menu-item tmi1 col-3">
                                        <img loading='lazy' src={`https://api.zakhira.store/${zoom1}`} alt='' onClick={()=>setZoom(zoom1)} />
                                    </div>
                                    <div className="thumb-menu-item tmi1 col-3">
                                        <img loading='lazy' src={`https://api.zakhira.store/${zoom2}`} alt='' onClick={()=>setZoom(zoom2)} />
                                    </div>
                                    <div className="thumb-menu-item tmi1 col-3">
                                        <img loading='lazy' src={`https://api.zakhira.store/${zoom3}`} alt='' onClick={()=>setZoom(zoom3)} />
                                    </div>
                                    <div className="thumb-menu-item tmi1 col-3">
                                        <img loading='lazy' src={`https://api.zakhira.store/${zoom2}`} alt='' onClick={()=>setZoom(zoom2)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-7 col-md-6 left">
                            <NavLink className='text-white left' to="#"><h1>{data1}</h1></NavLink>

                            <div className="row"> */}

                                {/* <!-- Content Start --> */}
                                {/* <div className="col-9 col-md-10 left">
                                    <div className="product-details-content"> 
                                        <div className="product-details-top">

                                            <h2 className='golden mt-2'>Description:</h2>
                                            {des[1]&&<li> {des[1]}</li>}
                                            {des[2]&&<li> {des[2]}</li>}
                                            {des[3]&&<li> {des[3]}</li>}
                                            {des[4]&&<li> {des[4]}</li>}
                                            {des[5]&&<li> {des[5]}</li>}
                                            {des[6]&&<li> {des[6]}</li>}
                                            {des[7]&&<li> {des[7]}</li>}
                                            {des[8]&&<li> {des[8]}</li>}
                                            {des[9]&&<li> {des[9]}</li>}
                                            {des[10]&&<li> {des[10]}</li>}
                                            {des[0]&&<><b className='check'><FcIcons.FcApproval /></b> {des[0]}</>}
                                            
                                            <div className="pb-5 pt-5">
                                                <div className="pt-3">{price==='offer'?'Retail':'Offer'} Price: <del>₹{data3}.00</del></div>
                                                <h1 className='golden pt-2'>{price==='offer'?'Offer':'Wholesale'} Price: ₹{data2}.00</h1>
                                            </div>

                                            <p>Shipping Charges :<b> {data2>199?'Free':'₹199/-'} </b></p>
                                            <p>Cash On Delivery :<b> ₹99/- (Advance)</b></p><br/>
                                            <h1 className='message'>Free Shipping On Orders Above ₹299/-</h1><br/>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <!-- Content End --> */}

                                {/* <!-- Icons Start --> */}
                                {/* <div className="col-3 col-md-2">
                                    <center>
                                        <img loading='lazy' src='images/shipping.png' className='shipping' height={60} alt='' /><br/>
                                        <b className='icons'>Drop&nbsp;Shipping<br/>Avaliable</b><br/>
                                        <img loading='lazy' src='images/reseller.png' className='reseller mt-4' height={45} alt='' /><br/>
                                        <b className='icons'>Resellers<br/>Welcome</b><br/>
                                        <a target='blank' href='https://api.whatsapp.com/send/?phone=7991620001&text&type=phone_number&app_absent=0'>
                                            <img loading='lazy' src='images/whatsapp.png' className='mt-4' height={60} alt='' /><br/>
                                        </a>
                                        <a target='blank' href='https://instagram.com/zakhira.store'>
                                            <img loading='lazy' src='images/instagram.png' className='mt-4 m-2' height={35} alt='' /><br/>
                                        </a>
                                        <a target='blank' href='https://www.youtube.com/hashtag/shorts'>
                                            <img loading='lazy' src='images/shorts.png' className='mt-4' height={40} alt='' /><br/>
                                        </a>
                                    </center>
                                </div> */}
                                {/* <!-- Icons End --> */}

                            {/* </div>

                            <h1 className='message tag'>Use it &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp; Gift it &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp; Resell it</h1>

                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div> */}
        {/* <!-- Modal End --> */}

    </div>
  )
}

export default HomePage