import React, { useEffect, useState } from 'react'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import * as RiIcons from 'react-icons/ri';
import * as FcIcons from 'react-icons/fc';

const ProductPage = (props) => {
    const price = props.price
    const [toggle,setToggle] = useState('')
    
    const params = useParams()
    const navigate = useNavigate()
    const [delay, setDelay] = useState(true)
    
    const [zoom, setZoom] = useState('')    
    const [images, setImages] = useState([])
    const [title, setTitle] = useState('')
    const [wholesale, setWholesale] = useState('')
    const [offer, setOffer] = useState('')
    const [retail, setRetail] = useState('')
    const [category, setCategory] = useState('')
    const [link, setLink] = useState('')
    const [description, setDescription] = useState('')

    useEffect(()=>{
        setTimeout(() => setDelay(false), 9999)
        getProduct()
        // eslint-disable-next-line 
    },[])
  
    // Pre-Filled Data
    const getProduct = async () => {
        let result = await fetch(`https://api.zakhira.store/updateProduct/${params.id}`)
        result = await result.json()
        setZoom(result.images[0])
        setImages(result.images)
        setTitle(result.title)
        setWholesale(result.wholesale)
        setOffer(result.offer)
        setRetail(result.retail)
        setCategory(result.category)
        setLink(result.link)
        setDescription(result.description)
    }
    const des = description.split('- ')

    const previous = () => {
        if(images.length===2){
            if(link){
                if(zoom===images[0]){ setToggle(true); }
                if(zoom===images[1]){ setZoom(images[0]); }
                if(toggle===true){ setToggle(false); setZoom(images[1]); }
            }
            else{
                if(zoom===images[0]){ setZoom(images[1]); }
                if(zoom===images[1]){ setZoom(images[0]); }
            }
        }
        if(images.length===3){
            if(link){
                if(zoom===images[0]){ setToggle(true); }
                if(zoom===images[1]){ setZoom(images[0]); }
                if(zoom===images[2]){ setZoom(images[1]); }
                if(toggle===true){ setToggle(false); setZoom(images[2]); }
            }
            else{
                if(zoom===images[0]){ setZoom(images[2]); }
                if(zoom===images[1]){ setZoom(images[0]); }
                if(zoom===images[2]){ setZoom(images[1]); }
            }
        }
    };
    
    const next = () => {
        if(images.length===2){
            if(link){
                if(zoom===images[0]){ setZoom(images[1]); }
                if(zoom===images[1]){ setToggle(true); }
                if(toggle===true){ setToggle(false); setZoom(images[0]); }
            }
            else{
                if(zoom===images[0]){ setZoom(images[1]); }
                if(zoom===images[1]){ setZoom(images[0]); }
            }
        }
        if(images.length===3){
            if(link){
                if(zoom===images[0]){ setZoom(images[1]); }
                if(zoom===images[1]){ setZoom(images[2]); }
                if(zoom===images[2]){ setToggle(true); }
                if(toggle===true){ setToggle(false); setZoom(images[0]); }
            }
            else{
                if(zoom===images[0]){ setZoom(images[1]); }
                if(zoom===images[1]){ setZoom(images[2]); }
                if(zoom===images[2]){ setZoom(images[0]); }
            }
        }
    };

    const logout = () => {
        localStorage.clear()
        navigate('/')
    }

return(
    <div className='container-fluid no-nav bg-dark'>

        <link rel="stylesheet" href="main.css"/>

        { price==='wholesale' &&
        <NavLink className="nav-link active text-white logout float-log mt-5" to="/" onClick={logout} ><RiIcons.RiLogoutCircleLine/></NavLink>}

        {/* <!-- Main Wrapper Start --> */}
        <div className="wrapper bg--shaft">

            {/* <!-- Breadcumb area Start --> */}
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="page-title">Product Detalis</h1>
                            <ul className="breadcrumb justify-content-center">
                                <li><NavLink to={price==='offer'?'/':'/userHome'}>Home</NavLink></li>
                                <li className="current"><NavLink to="#">Product Detalis</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcumb area End --> */}

            {/* <!-- Main Content Wrapper Start --> */}
            {title ?
                <div className="main-content-wrapper col-12">
                    <div className="container">
                        {/* <!-- Single Product Start --> */}
                        <section className="mirora-single-product pb--80 pb-md--60">
                            <div className="row">
                                
                                <div className="col-sm-6 pb-4">
                                    {/* <!-- Tab Content Start --> */}
                                    <div className="tab-pane  show " id="product-large-one">
                                        <div className="product-details-img easyzoom">
                                            {toggle?
                                            
                                                <iframe height={350} src={`https://www.youtube.com/embed/${link}?controls=0`} className='col-12 col-sm-12 col-md-12 col-lg-9 col-xl-8 rounded' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                
                                                :<img loading='lazy' src={`https://api.zakhira.store/${zoom}`} alt='' className='rounded' />}
                                                <h1 className='navigate previous' onClick={previous}>&nbsp;</h1>
                                                <h1 className='navigate next' onClick={next}>&nbsp;</h1>
                                                
                                                {link&&<center><span className='bg-danger p-2 rounded youtube' onClick={()=>setToggle(!toggle)}>{toggle?'Images':'Video'}</span></center>}
                                            
                                                {/* {'❮'}{'❯'} */}{/* <img loading='lazy' src={`https://api.zakhira.store/photos/${zoom}`} alt='' onClick={()=>{setShow(true); setSlider(zoom)} } /> */}
                                        </div>
                                    </div>
                                    {/* <!-- Tab Content End --> */}
                                    
                                    {/* <!-- Product Thumbnail Carousel Start --> */}
                                    <div className="thumb-menu row justify-content-center mt-4 mb-4">
                                        {( ((images.length)===0) || ((images.length)===1) ) ? null : <>
                                        <div className="thumb-menu-item tmi2 col-4 right col-lg-3">
                                            <img loading='lazy' src={`https://api.zakhira.store/${images[0]}`} className="rounded" alt='' onClick={ ()=>{setToggle(false);setZoom(images[0])} } />
                                        </div>
                                        
                                        <div className="thumb-menu-item tmi2 col-4 col-lg-3">
                                            <img loading='lazy' src={`https://api.zakhira.store/${images[1]}`} className="rounded" alt='' onClick={ ()=>{setToggle(false);setZoom(images[1])} } />
                                        </div>
                                        
                                        {images[2]&&
                                        <div className="thumb-menu-item tmi2 col-4 left col-lg-3">
                                            <img loading='lazy' src={`https://api.zakhira.store/${images[2]}`} className="rounded" alt='' onClick={ ()=>{setToggle(false);setZoom(images[2])} } />
                                        </div>} </> }
                                    </div>
                                    {/* <!-- Product Thumbnail Carousel End --> */}
                                </div>

                                {/* <!-- Single Product Content Start --> */}
                                <div className="col-sm-6">
                                    <div className="row">
                                        {/* <!-- Content Start --> */}
                                        <div className="col-9 col-md-10 left">
                                            <div className="product-details-content"> 
                                                <div className="product-details-top">
                                                <NavLink className='text-white left' to="#"><h1>{title}</h1></NavLink>

                                                    <h2 className='golden mt-5'>Description:</h2>
                                                    {des[1]&&<li> {des[1]}</li>}
                                                    {des[2]&&<li> {des[2]}</li>}
                                                    {des[3]&&<li> {des[3]}</li>}
                                                    {des[4]&&<li> {des[4]}</li>}
                                                    {des[5]&&<li> {des[5]}</li>}
                                                    {des[6]&&<li> {des[6]}</li>}
                                                    {des[7]&&<li> {des[7]}</li>}
                                                    {des[8]&&<li> {des[8]}</li>}
                                                    {des[9]&&<li> {des[9]}</li>}
                                                    {des[10]&&<li> {des[10]}</li>}
                                                    {des[0]&&<><b className='check'><FcIcons.FcApproval /></b> {des[0]}</>}
                                                    
                                                    <div className="mb-5 mt-5">
                                                        {
                                                            price==='offer'?
                                                            <>
                                                                <div className="pt-3">Retail Price: <del className='text-danger'>₹{retail}.00</del></div>
                                                                <h1 className='golden pt-2'>Offer Price: ₹{offer}.00</h1>
                                                            </>:
                                                            <>
                                                                <div className="pt-3">Offer Price: <del>₹{offer}.00</del></div>
                                                                <h1 className='golden pt-2'>Reseller Price: ₹{wholesale}.00</h1>
                                                            </>
                                                        }
                                                    </div>

                                                    <p>{(price==='offer')?(offer>1?<b className='text-success'>Shipping Extra</b>:<b>₹199/-</b>):(wholesale>1?<b  className='text-success'>Shipping Extra</b>:<b>₹199/-</b>)}</p>
                                                    <p>Cash On Delivery :<b className='text-success'> Available at ₹200 Extra in Advance</b></p><br/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- Content End --> */}

                                        {/* <!-- Icons Start --> */}
                                        <div className="col-3 col-md-2">
                                            <img loading='lazy' src='images/shipping.png' className='shipping' height={55} alt='' /><br/>
                                            <b className='icons'>Drop&nbsp;Shipping<br/>Available</b><br/>
                                            <a href='https://www.zakhirarewards.com'><img loading='lazy' src='images/reseller.png' className='reseller mt-4' height={50} alt='' /></a><br/>
                                            <b className='icons'>Resellers<br/>Welcome</b><br/>
                                            <a target='blank' href={price==='offer'?
                                                //    offer<1?`https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${images[0]}   ${description.split('- ')[1].replace('&','＆')}   |   Offer Price : ₹${offer}.00   |   Shipping Charges : ₹199.00&type=phone_number&app_absent=0`
                                                //    :`https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${images[0]}   ${description.split('- ')[1].replace('&','＆')}   |   Offer Price : ₹${offer}.00   |   Shipping Charges : Free !&type=phone_number&app_absent=0`
                                                //    :
                                                //    wholesale<1?`https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${images[0]}   ${description.split('- ')[1].replace('&','＆')}   |   Reseller Price : ₹${wholesale}.00   |   Shipping Charges : ₹199.00&type=phone_number&app_absent=0`
                                                //    :`https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${images[0]}   ${description.split('- ')[1].replace('&','＆')}   |   Reseller Price : ₹${wholesale}.00   |   Shipping Charges : Free !&type=phone_number&app_absent=0`
                                               `https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${images[0]}   |   Category : ${category}   |   Offer Price : ₹${offer}.00   |   Shipping Extra&type=phone_number&app_absent=0`
                                               :
                                               `https://api.whatsapp.com/send/?phone=7991620001&text=https://api.zakhira.store/${images[0]}   |   Category : ${category}   |   Reseller Price : ₹${wholesale}.00   |   Shipping Extra&type=phone_number&app_absent=0`
                                            }>
                                                <img loading='lazy' src='images/whatsapp.png' className='mt-4' height={55} alt='' /><br/>
                                            </a>
                                            <a target='blank' href='https://instagram.com'>
                                                <img loading='lazy' src='images/instagram.png' className='mt-4' height={30} alt='' /><br/>
                                            </a>
                                            <a target='blank' href='https://www.youtube.com/@zakhirastore'>
                                                <img loading='lazy' src='images/youtube.png' className='mt-5' height={25} alt='' /><br/>
                                            </a>
                                        </div>
                                        {/* <!-- Icons End --> */}
                                    </div>
                                    
                                    <h1 className='message tag'>Use&nbsp;it&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gift&nbsp;it&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resell&nbsp;it</h1>

                                </div>
                                {/* <!-- Single Product Content End --> */}

                            </div>
                        </section>
                        {/* <!-- Single Product End -->   */}
                    </div>
                </div> :
                delay?
                <h2 className='mt-5'>Loading <div className="spinner-border" role="status"></div></h2>:
                <h2 className='text-danger mt-5'>No Product Found</h2>
            }
            {/* <!-- Main Content Wrapper End --> */}

        </div>
        {/* <!-- Main Wrapper End --> */}



    </div>
  )
}

export default ProductPage