import React, { useState,useEffect} from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

const UpdateProduct = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [disabled,setDisabled] = useState(false)
  
  const [file0, setFile0] = useState();
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [update, setUpdate] = useState(false);
  const [images, setImages] = useState([])

  const [imgs, setImgs] = useState([])
  const [title,setTitle] = useState('')
  const [cost,setCost] = useState('')
  const [wholesale,setWholesale] = useState('')
  const [offer,setOffer] = useState('')
  const [retail,setRetail] = useState('')
  const [quantity,setQuantity] = useState('')
  const [category,setCategory] = useState('')
  const [link,setLink] = useState('')
  const [description,setDescription] = useState('')
  
  useEffect(()=>{
    getProduct()
    // eslint-disable-next-line 
  },[])

  // Pre-Filled Data
  const getProduct = async () => {
    let result = await fetch(`https://api.zakhira.store/updateProduct/${params.id}`)
    result = await result.json()
    setImgs(result.images)
    setTitle(result.title)
    setCost(result.cost)
    setWholesale(result.wholesale)
    setOffer(result.offer)
    setRetail(result.retail)
    setQuantity(result.quantity)
    setCategory(result.category)
    setLink(result.link)
    setDescription(result.description)
  }



  // Update Data
  const submit = async (e) => {
    try{
      if(title && cost && wholesale && offer && retail && quantity && category && description){
        setDisabled(true)

        if( (cost>0 && cost<999999) && (wholesale>0 && wholesale<999999) && (offer>0 && offer<999999) && (retail>0 && retail<999999) && (quantity>0 && quantity<999999) ){
        
          if(images.length===0){
            let result = await fetch(`https://api.zakhira.store/updateProduct/${params.id}`,{
              method:'put',
              body:JSON.stringify({ title, cost, wholesale, offer, retail, quantity, category, link, description }),
              headers:{'Content-Type':'application/json'}
            })
            result = await result.json()
          
            if(result.message){
              alert(result.message)
              navigate('/productList')
            }
            else{
              setDisabled(false)
              alert("Error")
            }
          }

          else if(images.length>3){
            setDisabled(false)
            alert('Maximum 3 Photos')
            setImages([])
          }

          else{
            let url = `https://api.zakhira.store/updatePhoto/${params.id}`
            
            const formData = new FormData()
            for (let i = 0; i < images.length; i++) {
              formData.append("images", images[i]);
            }
            formData.append('title',title)
            formData.append('cost',cost)
            formData.append('wholesale',wholesale)
            formData.append('offer',offer)
            formData.append('retail',retail)
            formData.append('quantity',quantity)
            formData.append('category',category)
            formData.append('link',link)
            formData.append('description',description)
            let result = await axios.put(url,formData)

            if(result.data.message){
              alert(result.data.message)
              navigate('/productList')
            }

          }
        }

        else{
          setDisabled(false)
          alert("Please Enter Correct Price")
        }

      }
      else{alert("Please All Fields")}
    }
    catch{
      setDisabled(false)
      alert("Error")
    }
  }

  const handleChange = (e) => {
    let file =(e.target.files);
    if(images.length===0){
      setUpdate(true)
      if(file[0]){ setFile0(URL.createObjectURL(file[0])) }
      if(file[1]){ setFile1(URL.createObjectURL(file[1])) }
      if(file[2]){ setFile2(URL.createObjectURL(file[2])) }
      setImages([...images, ...file])
    }
    else if(images.length===1){
      setUpdate(true)
      if(file[0]){ setFile1(URL.createObjectURL(file[0])) }
      if(file[1]){ setFile2(URL.createObjectURL(file[1])) }
      setImages([...images, ...file])
    }
    else if(images.length===2){
      setUpdate(true)
      if(file[0]){ setFile2(URL.createObjectURL(file[0])) }
      setImages([...images, ...file])
    }
    else{
      setUpdate(false)
      setFile0(); setFile1(); setFile2()
      alert('Maximum 3 Photos')
      setImages([])
    }
  }
  
  return (
    <div className='container mb-5 body'>
      <h2 className='text-primary mt-4'>Update Product</h2>

      {update || !title ?
      <div className='row justify-content-evenly mt-5'>
        <div className='col-2'><img className='rounded preview' src={file0} alt=''/></div>
        <div className='col-2'><img className='rounded preview' src={file1} alt=''/></div>
        <div className='col-2'><img className='rounded preview' src={file2} alt=''/></div>
      </div>:
      <div className='row justify-content-evenly mt-5'>
        <div className='col-2'><img className='rounded preview' src={`https://api.zakhira.store/${imgs[0]}`} alt=''/></div>
        <div className='col-2'><img className='rounded preview' src={`https://api.zakhira.store/${imgs[1]}`} alt=''/></div>
        <div className='col-2'><img className='rounded preview' src={`https://api.zakhira.store/${imgs[2]}`} alt=''/></div>
      </div>}

        <div className="row justify-content-evenly">
          <div className="col-10 col-md-6 col-lg-4 mt-4">
            <label className='p-2'><b>Images</b></label>
            <input type="file" className="form-control" accept='image/*' multiple name="images" 
            onChange={handleChange} />
          </div>

          <div className="col-10 col-md-6 col-lg-4 mt-4">
            <label className='p-2'><b>Title</b></label>
            <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Product Title" name="title"  
            value={title} onChange={(e)=>setTitle(e.target.value)} />
          </div>
        </div>
        

        <div className="row justify-content-evenly">
          <div className="col-10 col-md-6 col-lg-4 mt-4">
            <label className='p-2'><b>Cost Price</b></label>
            <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Cost Price" name="cost"  
            value={cost} onChange={(e)=>setCost(e.target.value)} />
          </div>
        
          <div className="col-10 col-md-6 col-lg-4 mt-4">
            <label className='p-2'><b>Wholesale Price</b></label>
            <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Wholesale Price" name="wholesale"  
            value={wholesale} onChange={(e)=>setWholesale(e.target.value)} />
          </div>
        </div>

        <div className="row justify-content-evenly">
          <div className="col-10 col-md-6 col-lg-4 mt-4">
          <label className='p-2'><b>Offer Price</b></label>
            <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Offer Price" name="offer"  
            value={offer} onChange={(e)=>setOffer(e.target.value)} />
          </div>
        
          <div className="col-10 col-md-6 col-lg-4 mt-4">
            <label className='p-2'><b>Retail Price</b></label>
            <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Retail Price" name="retail"  
            value={retail} onChange={(e)=>setRetail(e.target.value)} />
          </div>
        </div>
        
        <div className="row justify-content-evenly">
          <div className="col-10 col-md-6 col-lg-4 mt-4">
            <label className='p-2'><b>Quantity</b></label>
            <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Quantity" name="quantity"  
            value={quantity} onChange={(e)=>setQuantity(e.target.value)} />
          </div>

          <div className="col-10 col-md-6 col-lg-4 mt-4">
          <label className='p-2'><b>Category</b></label>
          <select className="form-control text-center" name="category"
          onChange={(e)=>setCategory(e.target.value)} value={category}>
            <option value="" disabled hidden>Select a Category</option>
            <option value="Wallets">Wallets</option>
            <option value="Belts">Belts</option>
            <option value="Watches">Watches</option>
            <option value="Bags">Bags</option>
            <option value="Accessories">Accessories</option>
            <option value="Shoes">Shoes</option>
            <option value="Sunglasses">Sunglasses</option>
            <option value="Tshirts">Tshirts</option>
            <option value="Shirts">Shirts</option>
            <option value="Jeans">Jeans</option>
            <option value="Trousers">Trousers</option>
            <option value="Jackets">Jackets</option>
            <option value="Under Garments">Under Garments</option>
            <option value="Socks">Socks</option>
            <option value="Accessories">Accessories</option>
          </select>
          </div>
        </div>
        
        <div className="row justify-content-evenly">
          <div className="col-9 mt-4">
            <label className='p-2'><b>Youtube Link Code</b></label>
            <input type="text" className="form-control text-center" autoComplete='off' placeholder="Enter Youtube Link Code" name="link"  
            value={link} onChange={(e)=>setLink(e.target.value)} />
          </div>
        </div>
        
        <div className="row justify-content-evenly">
          <div className="col-9 mt-4">
          <label className='p-2'><b>Description</b></label>
            <textarea type="text" className="form-control" autoComplete='off' placeholder="Short Description....." name="description"  
            value={description} onChange={(e)=>setDescription(e.target.value)} rows={8} />
          </div>
        </div>
        
        <button type="submit" className={`btn btn-primary col-4 col-md-2 mt-4 p-2 ${disabled ? 'disabled' : null}`} onClick={submit}>Submit</button>
    </div>
  )
}

export default UpdateProduct