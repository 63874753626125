import React from 'react'
import ProductPage from '../ProductPage'

const UserProduct = () => {
    let price = 'wholesale'
    return (
        <div>
            <ProductPage price={price} />
        </div>
    )
}

export default UserProduct
