import React from 'react'
import HomePage from '../HomePage'

const UserHome = () => {
    let price = 'wholesale'
    return (
        <div>
            <HomePage price={price} />
         </div>
  )
}

export default UserHome