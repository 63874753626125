import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import HomePage from '../HomePage'

const Home = () => {
    const navigate = useNavigate()
    
    useEffect(()=>{
        const auth = localStorage.getItem('admin')
        if (auth)(
            navigate('/productList')
        )
        const authstd = localStorage.getItem('user')
        if (authstd)(
            navigate('/userHome')
        )
    // eslint-disable-next-line 
    },[])

    let price = 'offer'

    return (
        <div>
            <HomePage price={price} />
         </div>
  )
}

export default Home
